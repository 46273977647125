<template>
  <div class="registry-sign-up-form">
    <form>
      <div class="registry-sign-up-form-content">
        <div class="company-info-wrapper">
          <div class="info-top">
            <h6>
              Add Manufacturer Information
            </h6>
            <router-link class="custom-btn4" to="/manufacturers">
              <span>Back</span>
            </router-link>
          </div>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-2 px-0 pr-md-2">
                <div class="user-profile-image">
                  <div class="profile-image">
                    <div id="preview" class="profile-image">
                      <img v-if="url" :src="url" />
                      <img
                        v-else
                        src="../../../assets/images/user-image.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="change-profile-image" @click="openinput()">
                    <form>
                      <input
                        type="file"
                        id="vue-file-upload-input-profile-photo"
                        class="d-none"
                        @change="onFileChange"
                        accept="image/png, image/gif, image/jpeg"
                      />

                      <div class="change-icon">
                        <span class="text">Add Profile Picture</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-10 p-0">
                <div class="row">
                  <div class="col-12 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="manufacturerName"
                        >Manufacturer Name <span class="astrick">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="manufacturerName"
                        placeholder="Manufacturer Name"
                        autocomplete="off"
                        v-model.trim="$v.details.manufacturer_name.$model"
                        :class="{
                          'is-invalid': $v.details.manufacturer_name.$error,
                          'is-valid': !$v.details.manufacturer_name.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.manufacturer_name.required"
                          >Manufacturer name is required</span
                        >
                        <span v-if="!$v.details.manufacturer_name.minLength"
                          >Manufacturer name must have at least
                          {{
                            $v.details.manufacturer_name.$params.minLength.min
                          }}</span
                        >
                        <span v-if="!$v.details.manufacturer_name.maxLength"
                          >Manufacturer name must have at most
                          {{
                            $v.details.manufacturer_name.$params.maxLength.max
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 px-0">
                    <div class="form-group">
                      <label for="manufacturerEmail"
                        >Email <span class="astrick">*</span></label
                      >
                      <input
                        type="email"
                        v-model.trim="$v.details.email.$model"
                        class="form-control"
                        id="manufacturerEmail"
                        placeholder="Email"
                        :class="{
                          'is-invalid': $v.details.email.$error,
                          'is-valid': !$v.details.email.$invalid,
                        }"
                        autocomplete="off"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.email.required"
                          >Email is required</span
                        >
                        <span v-if="!$v.details.email.email"
                          >Email must be valid</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="phone"
                        >Phone <span class="astrick">*</span></label
                      >
                      <input
                        type="text"
                        v-model.trim="$v.details.phone.$model"
                        class="form-control"
                        id="phone"
                        placeholder="phone"
                        :class="{
                          'is-invalid': $v.details.phone.$error,
                          'is-valid': !$v.details.phone.$invalid,
                        }"
                        autocomplete="off"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.phone.required"
                          >Phone number is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6 px-0">
                    <div class="form-group">
                      <label for="country"
                        >Country <span class="astrick">*</span></label
                      >
                      <input
                        type="text"
                        v-model.trim="$v.details.country.$model"
                        class="form-control"
                        id="country"
                        placeholder="Country"
                        :class="{
                          'is-invalid': $v.details.country.$error,
                          'is-valid': !$v.details.country.$invalid,
                        }"
                        autocomplete="off"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.country.required"
                          >Country is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-4 px-0 pr-md-2">
                <div class="form-group">
                  <label for="city">City <span class="astrick">*</span></label>
                  <input
                    type="text"
                    v-model.trim="$v.details.city.$model"
                    class="form-control"
                    id="city"
                    placeholder="City"
                    :class="{
                      'is-invalid': $v.details.city.$error,
                      'is-valid': !$v.details.city.$invalid,
                    }"
                    autocomplete="off"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.city.required"
                      >City is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-8 px-0">
                <div class="form-group">
                  <label for="address"
                    >Address <span class="astrick">*</span></label
                  >
                  <input
                    type="text"
                    v-model.trim="$v.details.address.$model"
                    class="form-control"
                    id="address"
                    placeholder="Address"
                    :class="{
                      'is-invalid': $v.details.address.$error,
                      'is-valid': !$v.details.address.$invalid,
                    }"
                    autocomplete="off"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.address.required"
                      >Address is required</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-12 px-0">
                <div
                  class="form-group d-flex align-items-center justify-content-start"
                >
                  <input
                    type="checkbox"
                    class="mr-2"
                    v-model="details.status"
                    id="manufacturerActive"
                  />
                  <label class="form-check-label" for="manufacturerActive"
                    >This manufacturer is active</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="custom-btn2"
        @click="saveData(0)"
        :disabled="isLoadingArray[0]"
      >
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Save</span>
      </button>
    </form>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: "ManufacturerForm",
  data: function() {
    return {
      details: {
        manufacturer_name: "",
        email: "",
        phone: "",
        city: "",
        country: "",
        address: "",
        status: false,
      },
      isLoadingArray: [],
      url: null,
      profilePhoto: null,
      oldPicture: true,
    };
  },
  validations: {
    details: {
      manufacturer_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25),
      },
      email: {
        required,
        email,
      },
      phone: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      address: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    saveData: function(index) {
      var formData = new FormData();
      formData.append("manufacturer_name", this.details.manufacturer_name);
      formData.append("email", this.details.email);
      formData.append("phone", this.details.phone);
      formData.append("city", this.details.city);
      formData.append("address", this.details.address);
      formData.append("country", this.details.country);
      formData.append("address", this.details.address);
      formData.append("picture", this.profilePhoto);
      formData.append("status", this.details.status == true ? 1 : 2);

      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "manufacturer/create", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$router.push({ name: "Manufacturers" });
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
};
</script>

<style></style>
