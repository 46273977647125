<template>
  <div class="app-page">
    <LeftMenu />
    <div class="main-wrapper">
      <Navbar />
      <div class="registry-wrapper">
        <ManufacturerForm />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import ManufacturerForm from "../components/manufacturer/manufacturerform/ManufacturerForm.vue";

export default {
  name: "CreateManufacturer",

  components: {
    Navbar,
    LeftMenu,
    ManufacturerForm,
  },

  data: function() {
    return {};
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },
  created() {},
};
</script>
