var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registry-sign-up-form"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_c('div',{staticClass:"info-top"},[_c('h6',[_vm._v(" Add Manufacturer Information ")]),_c('router-link',{staticClass:"custom-btn4",attrs:{"to":"/manufacturers"}},[_c('span',[_vm._v("Back")])])],1),_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 col-lg-2 px-0 pr-md-2"},[_c('div',{staticClass:"user-profile-image"},[_c('div',{staticClass:"profile-image"},[_c('div',{staticClass:"profile-image",attrs:{"id":"preview"}},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_c('img',{attrs:{"src":require("../../../assets/images/user-image.png"),"alt":""}})])]),_c('div',{staticClass:"change-profile-image",on:{"click":function($event){return _vm.openinput()}}},[_c('form',[_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"vue-file-upload-input-profile-photo","accept":"image/png, image/gif, image/jpeg"},on:{"change":_vm.onFileChange}}),_vm._m(0)])])])]),_c('div',{staticClass:"col-12 col-md-8 col-lg-10 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.manufacturer_name.$model),expression:"$v.details.manufacturer_name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.manufacturer_name.$error,
                        'is-valid': !_vm.$v.details.manufacturer_name.$invalid,
                      },attrs:{"type":"text","id":"manufacturerName","placeholder":"Manufacturer Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.manufacturer_name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.manufacturer_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.manufacturer_name.required)?_c('span',[_vm._v("Manufacturer name is required")]):_vm._e(),(!_vm.$v.details.manufacturer_name.minLength)?_c('span',[_vm._v("Manufacturer name must have at least "+_vm._s(_vm.$v.details.manufacturer_name.$params.minLength.min))]):_vm._e(),(!_vm.$v.details.manufacturer_name.maxLength)?_c('span',[_vm._v("Manufacturer name must have at most "+_vm._s(_vm.$v.details.manufacturer_name.$params.maxLength.max))]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.email.$model),expression:"$v.details.email.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.email.$error,
                        'is-valid': !_vm.$v.details.email.$invalid,
                      },attrs:{"type":"email","id":"manufacturerEmail","placeholder":"Email","autocomplete":"off"},domProps:{"value":(_vm.$v.details.email.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.email.required)?_c('span',[_vm._v("Email is required")]):_vm._e(),(!_vm.$v.details.email.email)?_c('span',[_vm._v("Email must be valid")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.phone.$model),expression:"$v.details.phone.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.phone.$error,
                        'is-valid': !_vm.$v.details.phone.$invalid,
                      },attrs:{"type":"text","id":"phone","placeholder":"phone","autocomplete":"off"},domProps:{"value":(_vm.$v.details.phone.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.phone, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.phone.required)?_c('span',[_vm._v("Phone number is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-6 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.country.$model),expression:"$v.details.country.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.country.$error,
                        'is-valid': !_vm.$v.details.country.$invalid,
                      },attrs:{"type":"text","id":"country","placeholder":"Country","autocomplete":"off"},domProps:{"value":(_vm.$v.details.country.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.country, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.country.required)?_c('span',[_vm._v("Country is required")]):_vm._e()])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.city.$model),expression:"$v.details.city.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.city.$error,
                    'is-valid': !_vm.$v.details.city.$invalid,
                  },attrs:{"type":"text","id":"city","placeholder":"City","autocomplete":"off"},domProps:{"value":(_vm.$v.details.city.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.city, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.city.required)?_c('span',[_vm._v("City is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 col-md-8 px-0"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.address.$model),expression:"$v.details.address.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.details.address.$error,
                    'is-valid': !_vm.$v.details.address.$invalid,
                  },attrs:{"type":"text","id":"address","placeholder":"Address","autocomplete":"off"},domProps:{"value":(_vm.$v.details.address.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.address, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.address.required)?_c('span',[_vm._v("Address is required")]):_vm._e()])])]),_c('div',{staticClass:"col-12 px-0"},[_c('div',{staticClass:"form-group d-flex align-items-center justify-content-start"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.status),expression:"details.status"}],staticClass:"mr-2",attrs:{"type":"checkbox","id":"manufacturerActive"},domProps:{"checked":Array.isArray(_vm.details.status)?_vm._i(_vm.details.status,null)>-1:(_vm.details.status)},on:{"change":function($event){var $$a=_vm.details.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.details, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.details, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.details, "status", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"manufacturerActive"}},[_vm._v("This manufacturer is active")])])])])])])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.saveData(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-icon"},[_c('span',{staticClass:"text"},[_vm._v("Add Profile Picture")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"manufacturerName"}},[_vm._v("Manufacturer Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"manufacturerEmail"}},[_vm._v("Email "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phone"}},[_vm._v("Phone "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"country"}},[_vm._v("Country "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"city"}},[_vm._v("City "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"address"}},[_vm._v("Address "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
}]

export { render, staticRenderFns }